@import "sass/vars";
.ui {
  &__container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    pointer-events: none;
    .isfullscreenvideo & {
      z-index: 3;
    }
  }
  &__icon {
    pointer-events: auto;
    position: absolute;
    width: calc(pxtorem($width-icon));
    height: calc(pxtorem($width-icon));
    user-select: none;
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    svg {
      width: 100%;
    }
    .removebg & {
      display: none;
    }
    .isfullscreenvideo & {
      display: none;
    }
    &--noopacity {
      opacity: 1 !important;
    }
  }
  &__tl {
    top: pxtorem($width-icon/$width-icon-padding-mod);
    left: pxtorem($width-icon/$width-icon-padding-mod);
    @include mobile() {
      top: pxtorem($width-icon/2);
      left: pxtorem($width-icon/2);
    }
  }
  &__tr {
    top: pxtorem($width-icon/$width-icon-padding-mod);
    right: pxtorem($width-icon/$width-icon-padding-mod);
    @include mobile() {
      top: pxtorem($width-icon/2);
      right: pxtorem($width-icon/2);
    }
  }
  &__tr2 {
    top: pxtorem(($width-icon*3.2)/$width-icon-padding-mod);
    right: pxtorem($width-icon/$width-icon-padding-mod);
    @include mobile() {
      top: pxtorem(($width-icon/2));
      right: pxtorem($width-icon/2);
    }
  }
  &__bl2 {
    bottom: pxtorem($width-icon *1.8);
    left: pxtorem($width-icon/$width-icon-padding-mod);
    @include mobile() {
      bottom: pxtorem($width-icon * 1.8);
      left: pxtorem($width-icon/2);
    }
  }
  &__bl {
    bottom: pxtorem($width-icon/$width-icon-padding-mod);
    left: pxtorem($width-icon/$width-icon-padding-mod);
    @include mobile() {
      bottom: pxtorem($width-icon/2);
      left: pxtorem($width-icon/2);
    }
  }
  &__br {
    bottom: pxtorem($width-icon/$width-icon-padding-mod);
    right: pxtorem($width-icon/$width-icon-padding-mod);
    @include mobile() {
      bottom: pxtorem($width-icon/2);
      right: pxtorem($width-icon/2);
    }
    .isfullscreenvideo &{
      display: flex;
    }
  }
  &__logo {
    pointer-events: none;
    top: pxtorem($width-icon/2);
    left: 50%;
    transform: translateX(-50%);
    width: pxtorem(300px);
    height: pxtorem($width-icon);
    //background-color: $col-highlight;
    display: flex;
    justify-content: center;
    z-index: 2;
    cursor: auto;
    img {
      height: 100%;
    }
    opacity: 1 !important;
  }
  &__skip {
    bottom: pxtorem($width-icon/$width-icon-padding-mod);
    //width: pxtorem($width-skip/$width-icon-padding-mod);
    //height: pxtorem($width-skip/$width-icon-padding-mod);
    //bottom: pxtorem(0px);
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    .isfullscreenvideo & {
      display: none !important;
    }
    img {
      width: auto;
      height: 100%;
    }
  }
}
.testtheme {
  position: absolute;
  display: flex;
  bottom: 8px;
  left: 50px;
  align-items: center;
  div {
    width: 24px;
    height: 24px;
    margin: 10px;
    border: 1px dashed rgba(255, 255, 255, 0.3);
    border-radius: 50%;
  }
  p {
    color: #ffffff;
  }
  b {
    font-weight: normal;
  }
}
.active {
  transition: 0.2s all;
}
.close_3d {
  @extend .corner_close;
  .removebg & {
    display: flex;
  }
}
.close_fullscreen {
  @extend .corner_close;
  display: none;
  .isfullscreenvideo & {
    display: flex !important;
  }
  .isfullscreenvideo & {
    display: flex !important;
  }
}

.corner_close {
  display: none;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 4;
  .removebg & {
    display: flex;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    width: calc(pxtorem($width-icon*0.35));
    height: calc(pxtorem($width-icon*0.35));
    path {
      fill: #ffffff !important;
    }
  }
}
