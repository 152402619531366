@import "sass/mixins";
@import "sass/breakpoints";
$col-primary: #000000;
$col-secondary: #ffffff;
$col-highlight: #7E1FFF;
$col-modalbg: rgba(255, 255, 255, 0.8);
$col-darkbg: rgba(0, 0, 0, 0.9);
$width-icon: 28px;
$width-skip: 64px;
$width-icon-padding-mod: 1.6;
$width-close-icon: 19px;
$border-radius: 6px;

@include desktop() {
  $width-icon: 36px;
}
