@import "sass/reset";
@import "sass/vars";
@import "sass/font";
@import "sass/breakpoints";
@import "sass/typeface";
@import "sass/cookieconsent";
@import "sass/starfield";
body, html {
  overflow: hidden !important;
  background-color: #000000;
  color: #ffffff;
  max-width: 100%;
  overscroll-behavior-x: none;
  * {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
}

img{-webkit-user-drag: none;}
canvas {
}
#root {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
html, body, #root, .App, .page {
  width: 100%;
  height: 100%;
}
.page {
  padding: 0px;
  box-sizing: border-box;
}
button {
  background-color: #ffffff;
  color: #666666;
  padding: 20px;
}

.previewVideo {
  //display: none;
  /*position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999999;
  width: 400px !important;
  height: auto;*/
}

.tempscenemanager {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 999999;
  width: 50% !important;
  height: auto;
  background-color: rgba(0,0,0,0.7);
  color: #ffffff;
  padding: 20px;
}

.page__home {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-start;
  align-items: center;
  width: 60vw;
  min-height: 700px;
  max-width: 800px;
  height: 100%;
  flex-direction: column;
  position: relative;
  z-index: 2;
  margin: auto;
  margin-top: 13vh;
  p, h1, h2, h3, h4, h5, h6 {
    text-align: center;
    color: #ffffff;
  }
  img {
    margin: auto;
  }
  .terms {
    position: absolute;
    bottom: pxtorem(32px);
    p {
      font-size: pxtorem(12px);
      line-height: 1.2;
    }
    a {
      color: inherit;
      font-weight: bold;
    }
  }
}
.page__darken {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0,0,0,.6);
}
.page__background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  background-size: cover;
  background-position: center;
}

/**
Debug
 */
.buildnumber {
  position: absolute;
  top: 0px;
  left: 64px;
  background-color: black;
  color: #ffffff;
  padding: 10px;
  z-index: 10000000;
  font-weight: normal;
  font-size: 12px;
  opacity: 1;
  transition: 1s opacity;
}

#myVideoId {
  position: absolute;
  top: 0;
  z-index: 99999;
  opacity: 1;
  pointer-events: auto;
}
.cont {
  width: 400px;
  height: 100px;
  position: absolute;
  top: 0;
  z-index: 999999;
}
.liveVideo {
  position: absolute;
  width: 178px;
  height: 100px;
  top: 100px;
  z-index: 99999;
  opacity: 1;
  pointer-events: auto;
}
.error {
  position: absolute;
  top: 0;
  z-index: 2;
  display: flex;
  width: 100%;
  height: 100%;
  background: black;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.App {
  transition: opacity 1s;
}

.tempp {
  position: absolute;
  z-index: 999999999;
  top: 0;
}
.vidcont {
  position: absolute;
  z-index: 1000000;
  bottom: 260px;
  left: 20px;
  //display: none !important;
  width: 237px;
  height: 200px;
  pointer-events: none;
  flex-direction: column-reverse;
  video {
    width: 150px;
  }
}

.debug {
  position: absolute;
  padding: 20px;
  top: 0;
  width: 100%;
  z-index: 9999999999999999999;
  pointer-events: none;
  button {
    pointer-events: auto;
  }
}
div:has( > .label) {
  pointer-events: none !important;
}
.iconlabel {
  transition: 0.3s opacity;
  opacity: 1 !important;
  .isModal & {
    opacity: 0 !important;
  }
  .is2d & {
    opacity: 0 !important;
  }
  .hideLabels & {
    display: none !important;
  }
}
.hotspotHtml {
  p {
    text-align: center;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
  }
}


.startbutton {
  margin: 2rem 0;
  width: 100%;
  max-width: pxtorem(350px);
  font-family: FontBold;
}
.button-text {
  font-family: FontBold;
}