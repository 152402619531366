.loadspinner {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
// from https://codepen.io/comehope/pen/YLRLaM
.loader {
  width: 32px;
  height: 32px;
  font-size: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(47px);
}

.loader .face {
  position: absolute;
  border-radius: 50%;
  border-style: solid;
  animation: animate 1s linear infinite;
}

.loader .face:nth-child(1) {
  width: 100%;
  height: 100%;
  color: white;
  border-color: currentColor transparent transparent currentColor;
  border-width: 0.4em 0.4em 0em 0em;
  --deg: -45deg;
  animation-direction: normal;
}

.loader .face .circle {
  position: absolute;
  width: 50%;
  height: 0.1em;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transform: rotate(var(--deg));
  transform-origin: left;
}


@keyframes animate {
  to {
    transform: rotate(1turn);
  }
}