$bp-mobile: 480px;
$bp-tablet: 1023px;
$bp-desktop: 1280px;
$bp-desktop-lg: 1600px;
@mixin mobile {
  @media (max-width: #{$bp-mobile - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: #{$bp-tablet - 1px}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$bp-tablet}) {
    @content;
  }
}
@mixin desktop-lg {
  @media (min-width: #{$bp-desktop-lg}) {
    @content;
  }
}



// Simple media queries mixins for phone and tablet. Including variables of the basic mobile size, portrait and landscape orientation in sass. Can be quick applied in a mini site.
// Author: Millie Lin
// website: www.millielin.com

// ***** Breakpoints for Responsive Screen
// width scale for phone
$phone-min-width: 320px !default;
$phone-max-width: 504px !default;
// height scale for phone
$phone-min-height: 346px !default;
$phone-max-height: 695px !default;
// width scale for tablet
$tablet-min-width: 480px !default;
$tablet-max-width: 1024px !default;
// height scale for tablet
$tablet-min-height: 800px !default;
$tablet-max-height: 1440px !default;
// Desktop Scale
$desktop-min-height: 768px !default;
$desktop-max-height: 1280px !default;


// For landscape phone
@mixin landscape-phone(){
  @media screen and (min-height: $phone-min-height) and (max-height: $phone-max-height){
    @content;
  }
}
