.hotspot {
  cursor: pointer;
  user-select: none;
  background-color: #FFD100;
  //background-image: url("./assets/ico_hotspot_plus.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 62px;
  position: relative;
  z-index: 0;
  border: 2px solid #d09f1b;
  //box-shadow: 2px -1px 13px 0px rgba(255,255,255,0.75);
  /*opacity: 1;
  transition-delay: 0.2s;*/
  user-select: none;
  /*.popup & {
    //display: none !important;
    transition-delay: 0.2s;
    opacity: 0;
  }*/

  &__label {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    //background-color: #d4cfc0;
    padding: 15px;
  }
}
.visible--hide {
  opacity: 0 !important;
}
.video3d {
  div {
    z-index: 1 !important;
  }
}
