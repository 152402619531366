.gamepad {
     position: absolute;
     z-index: 999999;
     top: 50%;
     transform: translateY(-50%);
     left: 0;
     width: 170px;
     padding: 10px 20px;
     background: rgba(0, 0, 0, 0.75);
     box-shadow: 0px 0px 26px 0px rgba(255,255,0,0.1);
     color: rgb(16,124,16);
     pointer-events: none;
     display: flex;
     align-items: center;
     &__icon {
          width: 50px;
          height: 50px;
          svg {
               width: 100%;
               height: auto;
               fill: rgb(16,124,16);
          }
     }
     &__copy {
          padding-left: 10px;
          font-size: 12px;
          line-height: 1;
          text-shadow: rgba(102,255,0,0.2) 0px 0px 20px;
     }
}
