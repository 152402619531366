@import "sass/mixins";
.home {
  background-color: red;
  &__startbutton {
    margin: pxtorem(32px) !important;
    max-width: 200px !important;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
}
