
.cp-infx-button a {
  --cpcpbuttonPadding: 1rem 1.5rem;
  cursor: pointer;
  padding: 1rem 3rem;
  border-radius: 0;
  font-size: 1.2rem;
  width: 100%;
  display: inline-flex;
  position: relative;
  background: linear-gradient(147deg, rgb(254, 89, 6) -13%, rgb(255, 217, 0) 118%);
  border-image-source: linear-gradient(147deg, rgb(254, 89, 6) -13%, rgb(255, 217, 0) 118%);
  border-width: 1px;
  border-image-repeat: stretch;
  border-image-width: 1;
  border-image-slice: 1;
  border-style: solid;
  -webkit-background-clip: text;
  color: rgba(0,0,0,0);
  text-transform: uppercase;
  --cpcpinset: 0.4rem;
  --cpcpline-color: var(--cpcpc-grey7);
  justify-content: center;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;

  --cpunit: 0.5rem;
  --cpbaseTextSize: 1rem;
  --cpspaceIncrement: 1.65;

  --cptext-s: var(--cpbaseTextSize);
  --cptext-m: calc(var(--cptext-s) * var(--cptextSizeIncrement));
  --cpbaseSizeText: 1rem;
  --cptextSizeIncrement: 1.18;
  --cpaccentH: 20;
  --cpaccentS: 99%;
  --cpaccentL: 51%;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  box-sizing: inherit;

  //border-width: 1px;
  border-image-repeat: stretch;
  transition: 0.05s box-shadow;
}
.pointer {
  padding-bottom: 0.8rem !important;
  &:hover {
    --cpinset: 0.4rem;
    justify-content: center;
    width: 100%;
    box-shadow: 0 0 8px 0px hsl(var(--cpaccentH), var(--cpaccentS), calc(var(--cpaccentL) - 10%)),0 0 19px 0px hsl(var(--cpaccentH), var(--cpaccentS), calc(var(--cpaccentL) - 20%));
    --cpline-color: hsl(var(--cpaccentH) calc(var(--cpaccentS) - 40%) calc(var(--cpaccentL) - 20%));
    &:before {
      left: var(--cpinset);
      right: auto;
      box-shadow:1px 1px 0 0 var(--cpline-color) inset, 0px 1px 0 0 var(--cpline-color);
    }
    &:after {
      right: var(--cpinset);
      left: auto;
      box-shadow:1px 1px 0 0 var(--cpline-color), 0px 1px 0 0 var(--cpline-color) inset;
    }
  }
}

.cp-infx-button a:disabled {
  pointer-events: none;
  opacity: .5
}

.cp-infx-button a:before,.cp-infx-button a:after {
  content: "";
  position: absolute;
  inset: var(--cpinset);
  width: 1rem;
  height: calc(100% - var(--cpinset)*2)
}

.cp-infx-button a:before {
  left: var(--cpinset);
  right: auto;
  box-shadow: 1px 1px 0 0 var(--cpline-color) inset,0px 1px 0 0 var(--cpline-color)
}

.cp-infx-button a:after {
  right: var(--cpinset);
  left: auto;
  box-shadow: 1px 1px 0 0 var(--cpline-color),0px 1px 0 0 var(--cpline-color) inset
}

.cp-infx-button a .button-text:before {
  content: "";
  position: absolute;
  inset: 0;
  background: #000;
  z-index: -1
}

.cp-infx-button a:hover {
  box-shadow: 0 0 8px 0px hsl(var(--cpaccentH), var(--cpaccentS), calc(var(--cpaccentL) - 10%)),0 0 19px 0px hsl(var(--cpaccentH), var(--cpaccentS), calc(var(--cpaccentL) - 20%));
  --cpline-color: hsl(var(--cpaccentH) calc(var(--cpaccentS) - 40%) calc(var(--cpaccentL) - 20%))
}

.cp-infx-button a:hover .button-text:before {
  background: hsl(var(--cpaccentH), 31%, 6%)
}

.cp-infx-button a:disabled {
  color: var(--cpc-accent)
}
.cp-infx-button {
  width: 100%;
}
.button-text {
  background: #FE5906;
  background: linear-gradient(to right, #fa7708 0%, #fa9e09 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/**
COOKIE CONSENT
 */
body.swal2-height-auto {
  height:100%!important;
}.swal2-container {

   backdrop-filter: blur(6px);
   display: flex !important;
   justify-content: center;
   height:100%
 }@media(pointer: fine) {
  .swal2-container.swal2-container,.swal2-shown.swal2-shown {
    padding-right:0 !important
  }
}.swal2-container.swal2-container.swal2-shown {
   background-color: rgba(18,27,38,.7)
 }.swal2-popup.swal2-popup {
    all: unset;
    --c-grey8: rgb(32, 35, 39);
    --c-grey5: rgb(110,121,134);
    --c-grey3: rgb(172, 179, 187);
    --fontFamily: 'Univers LT W01_57 Condensed', sans-serif;
    --c-body: var(--c-grey8);
    --unit: 0.5rem;
    --baseTextSize: 1rem;
    --spaceIncrement: 1.65;
    --space-s: var(--unit);
    --space-m: calc(var(--space-s) * var(--spaceIncrement));
    --space-l: calc(var(--space-m) * var(--spaceIncrement));
    --space-xl: calc(var(--space-l) * var(--spaceIncrement));
    --space-2xl: calc(var(--space-xl) * var(--spaceIncrement));
    --text-s: var(--baseTextSize);
    --text-m: calc(var(--text-s) * var(--textSizeIncrement));
    --baseSizeText: 1rem;
    --textSizeIncrement: 1.18;
    --accentH: 20;
    --accentS: 99%;
    --accentL: 51%;
    --c-accent: hsl(var(--accentH), var(--accentS), var(--accentL));
    --angle-length: clamp(60px, 5vw, 80px);
    --clip-width: 1px;
    margin-block-start:calc(var(--space-m)*1.25) !important;position: relative;
    padding: clamp(var(--space-m),4vh,var(--space-xl)) clamp(var(--space-m),4vw,var(--space-l));
    font-family: var(--fontFamily);
    font-weight: 400;
    color: var(--c-body);
    font-size: var(--baseTextSize);
    width: calc(100% - 1rem) !important;
    max-width: 90ch
  }.swal2-popup.swal2-popup>* {
     position: relative;
     z-index: 1
   }.swal2-popup.swal2-popup:before {
      content: "";
      position: absolute;
      inset: 0;
      background: var(--c-grey5);
      clip-path: polygon(0 0, calc(100% - var(--angle-length)) 0, 100% var(--angle-length), 100% 100%, var(--angle-length) 100%, 0 calc(100% - var(--angle-length)), 0 0, var(--clip-width) var(--clip-width), var(--clip-width) calc(100% - var(--angle-length) - (var(--angle-length) + var(--clip-width)) * 0.01), calc(var(--angle-length) + (var(--angle-length) + var(--clip-width)) * 0.01) calc(100% - var(--clip-width)), calc(100% - var(--clip-width)) calc(100% - var(--clip-width)), calc(100% - var(--clip-width)) calc(var(--angle-length) + (var(--angle-length) + var(--clip-width)) * 0.01), calc(100% - var(--angle-length) - (var(--angle-length) + var(--clip-width)) * 0.01) var(--clip-width), var(--clip-width) var(--clip-width))
    }.swal2-popup.swal2-popup:after {
       content: "";
       position: absolute;
       inset: 0;
       background: hsl(var(--secondaryH) calc(var(--secondaryS) - 15%) calc(var(--secondaryL) + 3%)/var(--box-opacity, 0.65));
       clip-path: polygon(0 0, calc(100% - var(--angle-length)) 0, 100% var(--angle-length), 100% 100%, var(--angle-length) 100%, 0 calc(100% - var(--angle-length)));
       z-index: -1
     }.swal2-popup.swal2-popup:after {
        background: rgba(18,27,38,.9)
      }.swal2-html-container {
         all: unset;
         padding: 3rem clamp(2rem,10vw,6rem);
         margin: 0 !important;
         font-size: 1rem !important;
         --buttonFontSize: clamp(0.75rem, 0.5rem + 1vw, 1rem)
       }.swal2-html-container a {
          color: inherit
        }.swal2-html-container>* {
           z-index: 1;
           position: relative
         }.swal2-html-container .infx-button a {

            --buttonPadding: 1rem 1.5rem;
            cursor: pointer;
            padding: var(--buttonPadding, var(--textFrameY) var(--textFrameX));
            border: none;
            background-color: var(--buttonBgColor, var(--c-buttonBg));
            border-radius: var(--buttonRadius, var(--radius));
            font-weight: var(--buttonWeight, 500);
            font-size: var(--buttonFontSize, inherit);
            color: var(--buttonColor, var(--c-body));
            letter-spacing: var(--buttonLetterSpace, 0.03em);
            transition: var(--buttonTransition, -webkit-filter 0.2s);
            transition: var(--buttonTransition, filter 0.2s);
            transition: var(--buttonTransition, filter 0.2s, -webkit-filter 0.2s);
            display: inline-flex;
            position: relative;
            background: linear-gradient(147deg, rgb(254, 89, 6) -13%, rgb(255, 217, 0) 118%);
            border-image-source: linear-gradient(147deg, rgb(254, 89, 6) -13%, rgb(255, 217, 0) 118%);
            border-width: 1px;
            border-image-repeat: stretch;
            border-image-width: 1;
            border-image-slice: 1;
            border-style: solid;
            -webkit-background-clip: text;
            color: rgba(0,0,0,0);
            font-family: "Univers LT W01_67 Bold_1476016",sans-serif;
            text-transform: uppercase;
            --inset: 0.4rem;
            --line-color: var(--c-grey7);
            justify-content: center
          }.swal2-html-container .infx-button a:disabled {
             pointer-events: none;
             opacity: .5
           }.swal2-html-container .infx-button a:before,.swal2-html-container .infx-button a:after {
              content: "";
              position: absolute;
              inset: var(--inset);
              width: 1rem;
              height: calc(100% - var(--inset)*2)
            }.swal2-html-container .infx-button a:before {
               left: var(--inset);
               right: auto;
               box-shadow: 1px 1px 0 0 var(--line-color) inset,0px 1px 0 0 var(--line-color)
             }.swal2-html-container .infx-button a:after {
                right: var(--inset);
                left: auto;
                box-shadow: 1px 1px 0 0 var(--line-color),0px 1px 0 0 var(--line-color) inset
              }.swal2-html-container .infx-button a .button-text:before {
                 content: "";
                 position: absolute;
                 inset: 0;
                 background: #000;
                 z-index: -1
               }.swal2-html-container .infx-button a:hover {
                  box-shadow: 0 0 8px 0px hsl(var(--accentH), var(--accentS), calc(var(--accentL) - 10%)),0 0 19px 0px hsl(var(--accentH), var(--accentS), calc(var(--accentL) - 20%));
                  --line-color: hsl(var(--accentH) calc(var(--accentS) - 40%) calc(var(--accentL) - 20%))
                }.swal2-html-container .infx-button a:hover .button-text:before {
                   background: hsl(var(--accentH), 31%, 6%)
                 }.swal2-html-container .infx-button a:disabled {
                    color: var(--c-accent)
                  }.swal2-html-container p {
                     color: var(--c-grey3)
                   }.swal2-html-container p b:only-child {
                      --headingFontSize: 1.5rem!important;
                      --headingColor: #fff!important
                    }.swal2-container.swal2-backdrop-show,.swal2-container.swal2-noanimation {
                       background-color: rgba(18,27,38,.7) !important
                     }.cookie-interface-inner>*+* {
                        margin-top: 1rem
                      }.cookie-interface-buttons {
                         display: flex;
                         gap: .5rem;
                         justify-content: flex-start;
                         flex-wrap: wrap
                       }.cookie-interface-buttons .infx-button {
                          flex: 1 1 auto;
                          position: relative;
                          display: flex;
                          justify-content: stretch
                        }.cookie-interface-buttons .infx-button a {
                           width: 100%
                         }.cookie-interface-buttons .infx-button:first-of-type {
                            margin-left: -0.5rem
                          }.cookie-interface-allow>a:first-child {
                             display: none !important
                           }
.infx-button {
  border: 1px solid #fa8306;
}