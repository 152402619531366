@import "sass/vars";
@import "sass/mixins";
.col-menu {}
.menu {
  user-select: none;
  transform-origin: top right;
  position: absolute;
  z-index: 3;
  //height: 100%;
  right: 0 !important;
  top: 0;
  box-sizing: border-box;
  //min-width: 375px;
  width: 600px;
  @include mobile() {
    //left: calc(pxtorem($width-icon) * 3);
  }
  //padding: calc(pxtorem($width-icon/2)) 0;
  box-sizing: border-box;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 1000%;
    background-color: inherit;
    z-index: -1;
    @extend .col-menu;
  }
  &__close {

    top: pxtorem($width-icon/$width-icon-padding-mod);
    right: pxtorem($width-icon/$width-icon-padding-mod);
    @include mobile() {
      top: pxtorem($width-icon/2);
      right: pxtorem($width-icon/2);
    }
    height: pxtorem($width-icon);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    img {
      width: 50%;
    }
    svg {
      width: 50%;
    }
  }
  &__help {
    z-index: 4;
    pointer-events: auto;
    top: pxtorem(($width-icon/$width-icon-padding-mod) * 3.4);
    right: pxtorem($width-icon/$width-icon-padding-mod);
    @include mobile() {
      top: pxtorem(($width-icon/2) * 3.4);
      right: pxtorem($width-icon/2);
    }
    height: pxtorem($width-icon);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      //width: 99%;
    }
  }
  &__content {
    position: relative;
    padding-top:  pxtorem($width-icon *4);
    padding-bottom:  pxtorem($width-icon *2);
    padding-left: pxtorem($width-icon *4);
    padding-right: pxtorem($width-icon *3);
    min-height: 1200px;
    p {
      //color: #ffffff;
      b {
        @extend h3
      }
    }
    h3 {
      //color: #ffffff;
    }
    a {
      //color: #999999;
    }
  }
  &__line {
    position: relative;
    left:  pxtorem(-$width-icon *2);
    width: 200%;
    border-top: 0.5px solid rgba(255, 255, 255, 0.3);
    margin: pxtorem($width-icon * 1.5) 0;
    &--min {
      width: 200%;
      left: 0;
      margin: pxtorem($width-icon * 0.5) 0;
    }
  }
  &__item {
    cursor: pointer;
    position: relative;
    /*opacity: 0.4;
    transition: 0.1s all;*/
    h3 {
      margin-bottom: pxtorem(4px * -1);
    }
    &--active {
      opacity: 1;
      &:after {
        //background-color: #7E1FFF !important;
      }
    }
    &:after {
      content: '';
      width: 9px;
      height: 9px;
      border-radius: 50%;
      position: absolute;
      left: pxtorem(24px * -1);
      top: pxtorem(28px);
      //background-color: #000000;
      transition: 0.2s all;
    }
    &:hover {
      &:after {
        background-color: #e7e7e7;
      }
    }
    &--input {
      &:before {
        content: 'A';
        font-family: FontBold;
        [data-input="key"] & {
          content: '<';
        }
        color: #000000;
        box-shadow: 0px 0px 26px 0px rgb(4 191 255 / 34%);
        position: absolute;
        width: 32px;
        height: 32px;
        background-color: white;
        border-radius: 50%;
        display: none;
        right: pxtorem(-58px);
        top: 50%;
        transform: translateY(pxtorem(-8px));
        align-items: center;
        justify-content: center;
        font-size: pxtorem(22px);
        .usingPad & {
          display: flex;
        }
      }
    }
  }


  &--open {
    transform: translateX(100%);
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-in !important;
  }
  &--closed {
    transform: translateX(0%);
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-out !important;
  }
  &__container {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    //width: 33%;
    height: 100vh;
    //background-color: #000000;
    @include tablet() {
      width: auto;
      left: calc(pxtorem($width-icon) * 3);
    }
    //transition: transform 0.6s ease-in;
  }
  &__cover {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0);
    top: 0;
    left: 0;
    &--open {
      pointer-events: auto;
    }
    &--closed {
      pointer-events: none;
    }
  }
}
.headericon {
  position: relative;
  img, svg {
    position: absolute;
    width: pxtorem($width-icon/1.8);
    transform: translateX( calc(pxtorem(-$width-icon) * 1) );
    top: pxtorem(11px);
  }
  a {
    color: inherit;
    font-weight: bold;
  }
}
.menushare {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: pxtorem(16px);
  svg {
    position: relative;
    width: 24px;
    margin-right: pxtorem(16px);
    transform: none;
    top: auto;
  }
  div {
    cursor: pointer;
  }
}
