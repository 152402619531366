$countdown-stroke-width:5;
$div-size: 20px;
$circle-size: 100%;

.helptimer {
     position: absolute;
     top: 16px;
     right: 16px;
     width: 24px;
     height: 24px;
     background: none;
     svg {
          height:$circle-size;
          transform: translate(0.5px, 0.5px) rotate(-90deg);
          width: $circle-size;
          overflow: visible !important;
     }
}


.countdown__background {
     fill: none;
     stroke: #cccccc;
     stroke-width: 0;
}
.countdown__progress {
     fill: none;
     stroke-width: $countdown-stroke-width;
     visibility:hidden;
     //opacity: 0.5;
}
#parent {
     position: relative;
     width: $circle-size;
     height:$circle-size;
     border-radius: 50%;
     overflow: visible;
}
