.previewVideo {
  //display: none;
  //position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999999;
  video {
    width: 400px !important;
    height: auto;
  }
}
