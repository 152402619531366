@import "sass/breakpoints";
@import "sass/mixins";
.ccwrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.55);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: #ffffff;
    text-decoration: underline;
  }
}
.CookieConsent {
  flex-direction: column;
  position: relative !important;
  width: 626px !important;
  height: 626px !important;
  max-width: 90% !important;
  padding: calculateRem(40px) calculateRem(100px);
  @include mobile() {
    padding: calculateRem(20px) calculateRem(40px);
  }
  font-size: calculateRem(19px);
  font-family: "GillSansNovaforJL-Light";
  text-align: center;
  background: rgba(0,0,0,0.9) !important;
  > div { // copy
    &:first-child {
      max-width: calculateRem(768px) !important;
      margin: 0px auto 32px !important;
      flex: 1 0 auto !important;
    }
  }
  > div {
    &:nth-child(2) { // buttons
      max-width: calculateRem(900px) !important;
      margin: 0px auto 36px!important;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      button {
        flex: 1 0 auto !important;
        margin: 0 10px !important;
        padding: 16px 10px !important;
        text-transform: uppercase;
        @include mobile() {
          padding: 10px 10px;
        }
      }
    }
  }
  h1 {
    margin-bottom: calculateRem(32px);
  }
  p {

    font-size: calculateRem(20px);
    line-height: 1.4;
  }
  div:nth-child(2) {
    display: flex;
    flex-direction: column;
  }
}
#rcc-confirm-button {
  background-color: #ffffff !important;
  transition: 0.2s opacity;
  width: calculateRem(285px) !important;
  @include mobile() {
    width: 100% !important;
    min-width: calculateRem(200px);
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  margin-bottom: calculateRem(18px) !important;
  &:hover {
    opacity: 0.47;
  }
}
#rcc-decline-button {
  background-color: transparent !important;
  box-shadow: inset 0 0 0 1px #ffffff !important;
  color: #ffffff !important;
  border: none;
  transition: 0.2s box-shadow;
  @include mobile() {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  &:hover {
    box-shadow: inset 0 0 0 3px #ffffff !important;
  }
}
